import React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

import {
  headline1,
  headline2,
  headline3,
  headline4,
} from "../components/headlines";
import ContactPerson from "../components/contactPerson";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";

const shortcodes = {
  h1: headline1,
  h2: headline2,
  h3: headline3,
  h4: headline4,
};

export default function NewsTemplate({ data: { mdx, marcel } }) {
  return (
    <Layout lang="de" langPath="nav.news.link">
      <Seo
        title={"News – " + mdx.frontmatter.title}
        description={mdx.frontmatter.teaser}
        keywords={mdx.frontmatter.keywords}
      />
      <div className="parallax-translate bg-shape-square" />
      <div className="container mt-4 mb-4 reveal">
        <div className="row">
          <div className="col-12">
            <span className="h5 text-color-monday-green">
              {mdx.frontmatter.date}
            </span>
            <h1 className="text-size-2 text-size-md-1">
              {mdx.frontmatter.title}
            </h1>
          </div>
        </div>
      </div>
      <div className="container mt-4 mb-4 reveal">
        <div className="row">
          <div className="col-12">
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </div>
      <div className="container reveal">
        <div className="row">
          <div className="col-12 mb-6">
            <Link to="/news" className="btn text-color-white bg-monday-skyblue">
              Zur Übersicht
            </Link>
          </div>
        </div>
      </div>
      <div id="get-in-touch">
        <ContactPerson
          headline="Darf es ein bisschen mehr sein?"
          text="Möchten Sie mehr über unsere aktuellen Themen erfahren? Dann nehmen Sie einfach Kontakt mit uns auf."
          contactEmail="hello@monday-consulting.com"
          contactPhone="+49 40 22 86 842 0"
          contactName="Marcel Scheland, Geschäftsführer"
        >
          <GatsbyImage
            image={marcel.childImageSharp.gatsbyImageData}
            alt="Marcel Scheland"
          />
        </ContactPerson>
      </div>
    </Layout>
  );
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        keywords
        teaser
      }
    }
    marcel: file(relativePath: { eq: "profiles/marcel-scheland.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;
